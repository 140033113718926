import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import IconExclamartionMark from './icons/IconExclamartionMark';
import { minAndMaxUserAges } from '../utils/constants';
import copys from '../utils/copys';


const changeLabelChildrenStyles = (labelRef,isFocused)=>{
  if (labelRef && labelRef.children) {
    Array.from(labelRef.children).forEach(child => {
      if(isFocused){
        child.style.fontSize='12px';
        child.style.lineHeight='16px';
      }else{
        child.style.fontSize='16px';
        child.style.lineHeight='24px';
      }
    });
  }
}

const currentYear = new Date().getFullYear();
const maxYear = currentYear - minAndMaxUserAges.min;
const minYear = currentYear - minAndMaxUserAges.max;

export const CustomInput = ({type,defaultValue,required,label,onChange,regExValidate,errorMessage,validateAge,id,maxLength}) => {
    const inputDivRef = useRef(null)
    const inputRef = useRef(null)
    const labelRef = useRef(null)

    const [inputValue, setInputValue] = useState(defaultValue||'');
    const [hasError,setHasError] = useState(false);
    const [customErrorMessage,setCustomErrorMessage] = useState(errorMessage);

    useEffect(()=>{
      if(validateAge){
          if(inputValue==='' || (inputValue <= maxYear && inputValue >= minYear)){
            setHasError(false);
            setCustomErrorMessage(errorMessage)
          }else if(inputValue<minYear){
            if(!hasError && inputValue.length===4){
              setCustomErrorMessage(copys.inputAgeErrorMessage100Years);
              setHasError(true);
            }
          }else{
            setCustomErrorMessage(errorMessage)
            setHasError(true);
          }
      }
    },[inputValue])


    const handleChange = ({target:{value}}) => {
      if(regExValidate){
        if(regExValidate.test(value)){
            setInputValue(value);
            onChange(value);
        }
      }else{
        setInputValue(value);
        onChange(value);
      }
    };

    const handleOnFocus = ()=>{
      inputDivRef.current.style.display='flex';
      changeLabelChildrenStyles(labelRef.current,true);
      inputRef.current.focus();
    }

    const handleOnBlur = ()=>{
      if(inputValue===''){
        inputDivRef.current.style.display='none';
        changeLabelChildrenStyles(labelRef.current,false);
      }else{
        inputDivRef.current.style.display='flex';
      }
    }

  return (

    <div className='flex container flex-col items-center gap-1'>
      <div className="flex items-start container gap-1 justify-center">
        <div tabIndex={0} className={`rounded-lg border border-solid flex p-4 items-center justify-between gap-2 h-14 container basis-full min-[340px]:basis-11/12 focus-within:border-2 ${hasError? 'border-border_danger':'border-border_primary'}`} onFocus={handleOnFocus} >
          <div className="flex items-center gap-2 justify-start">
            <div className="flex flex-col justify-center items-start">
              <div className="flex items-start gap-1 self-stretch" ref={labelRef}>
                <span className="text-white font-roboto text-base font-normal">
                  {label}
                </span>
                {
                    required && (
                      <span className="text-text_secondary font-roboto font-normal text-base">
                        *
                      </span>
                    )
                }
              </div>
              <div className="items-start gap-1 self-stretch hidden" ref={inputDivRef}>
                <input 
                  id={id}
                  ref={inputRef}
                  value={inputValue}
                  onChange={handleChange} 
                  type={type} 
                  className='appearance-none text-white font-roboto text-base font-normal text-start' 
                  onBlur={handleOnBlur}
                  maxLength={maxLength? maxLength:99999999}
                />
              </div>
            </div>
          </div>
          <div className={` w-6 h-6 shrink-0 ${hasError? '':'invisible'}`}>
            <IconExclamartionMark fill='#FA7490' />
          </div>
        </div>
      </div>
      <div className="flex flex-col container items-center">
        <div id={`${id}_ErrorMessage`} className={`flex justify-center text-left w-full font-roboto font-normal text-xs ${hasError? 'text-border_danger':'text-white'}`}>
          <div className="basis-full min-[340px]:basis-11/12">
            {customErrorMessage}
          </div>
        </div>
      </div>
    </div>
  );
}

CustomInput.propTypes = {
    type: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any,
    required:PropTypes.bool,
};

CustomInput.defaultProps = {
    type: 'text',
    label: '',
    value: '',
    required:false,
};