import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import {CustomInput} from '../components/CustomInput'
import copys, { genderOptions } from '../utils/copys'
import { useEffect, useState } from "react";
import { signUp } from "../services/authService";
import { Logo } from "../components/Logo";
import { useAuthContext } from "../hooks/useAuthContext";
import { minAndMaxUserAges, routePaths, toastTypes } from "../utils/constants";
import RadioGroup from "../components/Radios";
import toast from "react-hot-toast";
import Toast from "../components/Toast";
import { usePromoContext } from "../hooks/usePromoContext";
import LoadingSpinner from "../components/LoadingSpinner";
import gtmService from '../services/gtmService'
import { useToast } from "../hooks/useToast";

const currentYear = new Date().getFullYear();
const maxYear = currentYear - minAndMaxUserAges.min;
const minYear = currentYear - minAndMaxUserAges.max;

export default function ScreenRegister() {


    const [buttonEnabled, setButtonEnabled] = useState(false);
    const navigate = useNavigate();
    const {userData,setUserData}= useAuthContext();
    const {promoData } = usePromoContext();
    const [isLoading,setIsLoading] = useState(false);
    const {showToast} = useToast();

    const [formData,setFormData] = useState({
        name:'',
        birth_year:null,
        gender:null,
        phone_number:userData.phoneToValidate?.replace(' ','')
    })

    useEffect(()=>{
        if(userData.isLogged){
            navigate('/');
        }
        gtmService.pushPageView('/cliente-datos-de-usuario','Cliente: Datos de usuario')
    },[])

    useEffect(()=>{
        const {name,birth_year} = formData;
        if(name!=='' && (birth_year <= maxYear && birth_year >= minYear)){
            setButtonEnabled(true);
        }else{
            setButtonEnabled(false);
        }
    },[formData])

    useEffect(()=>{
        if(userData.isLogged){
            if(promoData.promoId!==0){
                navigate(`/${routePaths.DETAIL.replace(':promoId',promoData.promoId)}`);
            }else{
                navigate('/'+routePaths.HOME);
            }
        }
    },[userData])

    const onGenderChange = value =>{
        setFormData({...formData,gender:value});
    }

    const onNameChange = value =>{
        setFormData({...formData,name:value});
    }

    const onYearChange = value =>{
        setFormData({...formData,birth_year:value==='' ? null:value});
    }

    const onSubmit = async() =>{
        setIsLoading(true);
        gtmService.pushEvent({
            age:new Date().getFullYear()-formData.birth_year,
            gender:formData.gender
        })
        gtmService.pushClickButton(copys.labelBtnContinuar)
        const {successful, message, data} = await signUp(formData);
        setIsLoading(false);
        if(successful){
            const {id,birth_year,name,gender} = data.user.userFounded;
            const newUserData = {
                token:data.token,
                user:{
                    id,
                    birth_year,
                    name,
                    gender,
                }
            }
            setUserData({...newUserData,isLogged:true});
            showToast(toastTypes.SUCCESS,copys.toastRegisterSuccessful)
            gtmService.pushNotification('success',copys.toastRegisterSuccessful)
        }else{
            showToast(toastTypes.ERROR,copys.toastErrorMessageDefault)
            gtmService.pushNotification('error',message)
        }
    }


  return (
    <div className="min-h-screen pt-10 bg-money-image bg-cover bg-center flex flex-col items-center">
        <div className="container flex flex-col px-5 text-center items-center min-[480px]:w-[480px] pb-16">
            <div className="my-3 mb-5">
                <Logo customWidth={'w-[130px]'}/>
            </div>
            <div className="my-3">
                <div className="text-white text-center font-libre-franklin text-2xl font-bold" id="screenRegisterTitle">
                    {copys.labelRegisterScreenTitle}
                </div>
            </div>
            <div className="my-3">
                <div className="text-base font-roboto text-white text-center" id="screenRegisterSubtitle">
                    {copys.labelRegisterScreenSubTitle}
                </div>
            </div>
            <div className="flex flex-col justify-center items-center container">
                <div className="my-3 container">
                    <CustomInput id={'inputName'} label={copys.labelNameInput} type={'text'} required onChange={onNameChange} maxLength={50}/>
                </div>
                <div className="my-3 container">
                    <CustomInput id={'inputYear'} errorMessage={copys.inputAgeErrorMessage} validateAge={true} required onChange={onYearChange} regExValidate={new RegExp('^[0-9]{0,4}$')} label={copys.labelYearBirthInput} type={'text'} maxLength={4}  />
                </div>
            </div>
            
            <div className="my-3 container">
                <RadioGroup options={genderOptions} onChange={onGenderChange}/>
            </div>
            <div className="my-3 px-5">
                <Button id={'continue'} label={copys.labelBtnContinuar} enabled={buttonEnabled} onClick={onSubmit} isLoading={isLoading} icon={isLoading && { icon:<LoadingSpinner width={20} color={'#0C0D0F'} />,position:'end'}}/>
            </div>
        </div>
    </div>
  )
}
