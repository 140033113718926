import { axiosRequest } from '../utils/axiosRequest';
import { httpVerbs } from '../utils/constants';

export const sendOtp = async (phone) => {
    try {
        const phone_number = phone.replace(' ', '');
        return await axiosRequest(httpVerbs.POST, '/v1/login/otp', { phone_number })
    } catch (error) {
        console.log(error)
    }

}


export const validateOtp = async (phone, OTP) => {
    try {
        const phone_number = phone.replace(' ', '');
        const otp = Number(OTP);
        return await axiosRequest(httpVerbs.POST, '/v1/login/validate', { phone_number, otp })
    } catch (error) {
        console.log(error);
    }
}

export const signUp = async (formData) => {
    return await axiosRequest(httpVerbs.POST, '/v1/signup', formData);
}

