export const countryPhoneCodes = {
    'MX': '+52'
}

export const otpValidationStatus = {
    INCORRECT_OTP: "INCORRECT_OTP",
    EXPIRED_OTP: "EXPIRED_OTP",
    USER_NOT_REGISTERED: "user_not_found",
}

export const toastTypes = {
    SUCCESS: 'SUCCESS',
    INFO: 'INFO',
    SYSTEM: 'SYSTEM',
    ERROR: 'ERROR',
}

export const minAndMaxUserAges = {
    min: 18,
    max: 100
}

export const promoStatus = {
    AVAILABLE: 1,
    ACTIVE: 2,
    ONHOLD: 3,
    DISABLED: 4,
    EXPIRED: 5,
}

export const genders = {
    'M': 'Masculino',
    'O': 'Otro',
    'F': 'Femenino'
}

export const termsAndPrivacyScreenTypes = {
    TERMS: 'terms',
    PRIVACY: 'privacy',
}


export const httpVerbs = {
    GET: 'GET',
    POST: 'POST',
}

export const routePaths = {
    HOME: 'home',
    DETAIL: 'promo/:promoId',
    PROFILE: 'profile',
    SIGNUP: 'signup',
    LOGIN: 'login',
    OTP: 'otp',
    REGISTER: 'register',
    TERMS: 'terms/:type',
}
